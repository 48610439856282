//
//
//
//
//
//
//
//
//
//
//
//

import LookForm from "../../components/lookForm";
import LookFormApproval from "../../components/lookFormApproval";
// 超标
import overproofDetail from "@/lib/data-service/haolv-default/consumer_overproof_detail";
 //改签
import getApplyChange from "@/lib/data-service/haolv-default/consumer_journey_getApplyChange";
 //退票
import getApplyRefund from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund";
// 出差单
import applyDetail from "@/lib/data-service/haolv-default/consumer_apply_detail";
// 
import getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail";
//
import consumer_apply_workflowDetail from '@/lib/data-service/haolv-default/consumer_apply_workflowDetail';
import {approveFlow_matching, getProcessInstanceInfo} from "@/lib/data-service/haolv-default/design";
export default {
  name: "ApproveInfo",
  data() {
    return {
      type: "",
      detailData: {},
      approveType: null,
      loadingBox: false,
    };
  },
  components: { LookForm,LookFormApproval },
  methods: {
    getDetail() {
      this.loadingBox = true;
      let evectionNo = this.$route.query.evectionNo || "";
      let id = this.$route.query.applyId || "";
      let applyNo = this.$route.query.applyNo || '';
      let appType = parseInt(this.$route.query.appType);
      let selectType = parseInt(this.$route.query.selectType) || '';
      let approveStatus = parseInt(this.$route.query.approveStatus) || '';
      if (this.$store.state.workflow === '2') {
        applyDetail({ id, applyNo, appType, selectType, approveStatus}).then(res => {
          this.detailData = res.datas;
          this.loadingBox = false;
        });
      }
      if (this.$store.state.workflow === '1') {
        // appType: 1.我的申请 2待我审批
        consumer_apply_workflowDetail({applyNo: applyNo, appType, selectType, approveStatus}).then(res=>{
          this.detailData = res.datas;
          this.loadingBox = false;
          this.$nextTick(()=>{
              if (this.approveType == 7 || this.approveType == 8 || this.approveType == 9) {
                  this.$refs.LookFormApproval.getFlow();
              } else {
                  this.$refs.lookForm.getFlow();
              }
          });
        });

        /*getProcessInstanceInfo(applyNo).then(res=>{

        });*/
      }
      if (evectionNo) {
        getEvectionDetail({ evectionNo, pageType: 2 }).then(res => {
          this.detailData = res.datas;
          this.loadingBox = false;
            this.$nextTick(()=>{
                if (this.approveType == 7 || this.approveType == 8 || this.approveType == 9) {
                    this.$refs.LookFormApproval.getFlow();
                } else {
                    this.$refs.lookForm.getFlow();
                }
            });
        });
      }
    },
    _overproofDetail(applyId, applyNo, appType, selectType, approveStatus) {
      this.loadingBox = true;
      overproofDetail({ applyId, applyNo, appType, selectType, approveStatus }).then(res => {
        let detail = res.datas;
        const { applyName, applyNo, evectionDetailResponse, overproofs, recordList, tripPersons} = detail;
        this.detailData = {
          evection: evectionDetailResponse,
          overproofs: {
            applyNo: applyNo,
            applyName: applyName,
            feeAffiliationName: evectionDetailResponse.feeAffiliationName,
            overproofList: overproofs,
          },
          
          recordList: recordList,
          staffDetailVos: tripPersons ? tripPersons.map((item) => {return {staffName: item};}) : evectionDetailResponse.travellerList,
        }
        this.loadingBox = false;
        if(this.$store.state.workflow === '2'){
          return;
        }
          this.$nextTick(()=>{
              if (this.approveType == 7 || this.approveType == 8 || this.approveType == 9) {
                  this.$refs.LookFormApproval.getFlow();
              } else {
                  this.$refs.lookForm.getFlow();
              }
          });
      });
    },
    _getApplyChange(id, applyNo, appType, selectType, approveStatus) {
      this.loadingBox = true;
      getApplyChange({ id, applyNo, appType, selectType, approveStatus }).then(res => {
        let detail = res.datas;
        const type = detail.type;
        if (type === 4) {

        } else if (type === 5) {

        }
        this.detailData = detail;
        this.loadingBox = false;
        if(this.$store.state.workflow === '2'){
          return;
        }
          this.$nextTick(()=>{
              if (this.approveType == 7 || this.approveType == 8 || this.approveType == 9) {
                  this.$refs.LookFormApproval.getFlow();
              } else {
                  this.$refs.lookForm.getFlow();
              }
          });
      });
    },
    _getApplyRefund(applyId, applyNo, appType, selectType, approveStatus) {
      this.loadingBox = true;
      getApplyRefund({id: applyId, applyNo, appType, selectType, approveStatus}).then(res => {
        let detail = res.datas;
        const type = detail.type;
        if (type === 1 && detail.todHotelOrderInterimResponse.orderPersonList) {

        } else if (type === 2 && detail.refundOrdersDetailRespone.result.voyageMessageList[0].airplanePassengerList) {

        } else if (type === 3 && detail.trainsOrderReturnDetailResponse.passengers) {

        }
        this.detailData = detail;
        this.loadingBox = false;
        if(this.$store.state.workflow === '2'){
          return;
        }
          this.$nextTick(()=>{
              if (this.approveType == 7 || this.approveType == 8 || this.approveType == 9) {
                  this.$refs.LookFormApproval.getFlow();
              } else {
                  this.$refs.lookForm.getFlow();
              }
          });
      });
    }
  },
  created() {
    
  },
  async activated () {
    await this.$store.state.workflowDefer.promise;
    await this.$store.state.userInfoDefer.promise;
    //approveType   '审批类型：1.出差申请，2超标申请，3.报销申请，4.改签申请，5.退票申请',
    let applyId = this.$route.query.applyId;
    let applyNo = this.$route.query.applyNo;
    let appType = parseInt(this.$route.query.appType);
    // info 详情 havaReview 已审批 noReview 未审批
    this.type = this.$route.query.type || "";
    this.approveType = this.$route.query.approveType;
      let selectType = parseInt(this.$route.query.selectType) || ''; // 查询类型：1.我申请，2.我参与"
      let approveStatus = parseInt(this.$route.query.approveStatus) || '';
    if (this.$route.query.approveType == 2) {
      this._overproofDetail(applyId, applyNo, appType, selectType, approveStatus);
    } else if (this.$route.query.approveType == 3) {

    } else if (this.$route.query.approveType == 4 || this.$route.query.approveType == 8) {
      this._getApplyChange(applyId, applyNo, appType, selectType, approveStatus);
    } else if (this.$route.query.approveType == 5 || this.$route.query.approveType == 9) {
      this._getApplyRefund(applyId, applyNo, appType, selectType, approveStatus);
    } else {
      this.getDetail();
    }
  },
  beforeRouteEnter (to, from, next) {
    to.meta.lastMenu = to.query.lastMenu;
    next();
  },
  watch: {},
  computed: {}
};
